@font-face {
  font-family: NanumBarunGothicUltraLight;
  src: url("../../../public/assets/NanumBarunGothicUltraLight.ttf");
}
@font-face {
  font-family: NanumBarunGothic;
  src: url("../../../public/assets/NanumBarunGothic.ttf");
}
*{
  box-sizing: border-box;
}

.take-it-button {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: calc(100% - 40px);
  background: #51ba78;
  user-select: none;
  color: #fff;
  border: 0;
  padding: 20px;
  cursor: pointer;
  text-decoration: none;
  font-family: NanumBarunGothic;
  font-family: 14px;
}


/*Camera*/
.camera{
  z-index: 1000;
  display: block;
  width: 100vw;
  height: 100vh;
  position: relative;
}
.camera .camera-frame{
  position: fixed;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 60%;
}
.camera .camera-frame .left,
.camera .camera-frame .right
{
  position: absolute;
  width: 20px;
  height: 20px;
}
.camera .camera-frame .left:after,
.camera .camera-frame .right:after{
  content: '';
  width: 20%;
  height: 100%;
  position: absolute;
  background: #51ba78;
}

.camera .camera-frame .left:before,
.camera .camera-frame .right:before{
  content: '';
  width: 100%;
  height: 20%;
  position: absolute;
  background: #51ba78;
}

.camera .camera-frame .left{
  left: 0;
}

.camera .camera-frame .left:after, .camera .camera-frame .left:before {
  left: 0;
}

.camera .camera-frame .right:after, .camera .camera-frame .right:before {
  right: 0;
}

.camera .camera-frame .top:after, .camera .camera-frame .top:before {
  top: 0;
}

.camera .camera-frame .bottom:after, .camera .camera-frame .bottom:before {
  bottom: 0;
}

.camera .camera-frame .right{
  right: 0;
}
.camera .camera-frame .top{
  top: 0;
}
.camera .camera-frame .bottom{
  bottom: 0;
}
.camera .camera-msg {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: 9;

    font-family: NanumBarunGothic;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: -0.32px;
    text-align: center;
    color: #51ba78;
}

.camera .black-bg{}
.camera .black-bg .top,
.camera .black-bg .bottom,
.camera .black-bg .left, 
.camera .black-bg .right{
  background: rgba(0, 0, 0, .7);
  position: fixed;
}

.camera .black-bg .top,
.camera .black-bg .bottom{
  height: 20%;
  width: 100%;
  left: 0;
}


.camera .black-bg .left, 
.camera .black-bg .right{
  height: 60%;
  width: 10%;
  top: 20%;
}

.camera .black-bg .top{
  top: 0;
}
.camera .black-bg .left{
  left: 0;
}
.camera .black-bg .right{
  right: 0;
}
.camera .black-bg .bottom{
  bottom: 0;
}

/*POPUP Code*/
.page-item-wrap{
  background: rgba(0,0,0,.7);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.page-item-wrap .box-item-wrap{
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}
.page-item-wrap .box-item-wrap .btitle, 
.page-item-wrap .box-item-wrap .bmsg {
  font-size: 18px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.34px;
  text-align: center;
  color: #222;
}
.page-item-wrap .box-item-wrap .btitle{
  font-family: NanumBarunGothic;

}
.page-item-wrap .box-item-wrap .bmsg{
  font-family: NanumBarunGothicUltraLight;
}
.page-item-wrap .box-item-wrap .bpic{
  width: calc(100% - 100px);
  height: 200px;
  margin: 30px 0;
}
.page-item-wrap .box-item-wrap .bpic img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.page-item-wrap .box-item-wrap .btn-item-wrap{
  width: 100%;
}
.page-item-wrap .box-item-wrap .btn-item-wrap button{
  width: 100%;
  background: #51ba78;
  color: #fff;
  border: 0;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-family: NanumBarunGothic;
  font-size: 14px;
}